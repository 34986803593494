import * as CatalogClient from 'Clients/Catalog';

import { createPaginationActions } from 'Store/Pagination/Actions';
import createPaginationSelectors from 'Store/Pagination/Selectors';
import Config from 'Config';

const PAGINATION_STORE_NAME: string = 'mainSearch';
export const PAGE_SIZE: number = 100;

export type SearchArgs = {
  brandIds: number[];
  categoryIds: number[];
  query: string;
  orderBy: string[];
};

export const defaultArgs: SearchArgs = {
  brandIds: [],
  categoryIds: [],
  query: '',
  orderBy: Config.studio.orderBy
};

export const actions = createPaginationActions<TrykApi.Catalog.ISearchResult, SearchArgs>(PAGINATION_STORE_NAME, {
  getItemKey,
  getStoreKey,
  loadPage: (page, opts) => CatalogClient.Product.getSearchPage({
    brandIds: opts.brandIds,
    categoryIds: opts.categoryIds,
    query: opts.query,
    pageSize: page.size,
    page: page.index,
    orderBy: opts.orderBy
  })
});

export const selectors = createPaginationSelectors<TrykApi.Catalog.ISearchResult, SearchArgs>(PAGINATION_STORE_NAME, {
  getItemKey,
  getStoreKey,
  pageSize: PAGE_SIZE
});

function getItemKey(item: TrykApi.Catalog.ISearchResult): string {
  return `${item.designId}/${item.colorwayId}`;
}

function getStoreKey(args: SearchArgs, pageSize: number): string {
  const hasCategories = args.categoryIds && args.categoryIds.length > 0,
    hasQuery = !!args.query,
    hasBrands = args.brandIds && args.brandIds.length > 0;

  const parts: string[] = [];

  if (hasCategories) {
    parts.push(`c:${args.categoryIds.sort().join(',')}`);
  }

  if (hasBrands) {
    parts.push(`b:${args.brandIds.sort().join(',')}`);
  }

  if (hasQuery) {
    parts.push(`q:${args.query.toLowerCase()}`);
  }

  if (parts.length === 0) {
    return 'all';
  } else {
    return parts.join('/');
  }
}
